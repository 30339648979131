$(document).ready(function($) {

	let searchBtn = $('#navbar-search-btn');
	let searchBar = $('#navbar-search-bar');
	let searchBarInput = $('#navbar-search-bar-input');

	$(searchBtn).click(toggleSearchbar);

	function toggleSearchbar() {

		if ($(searchBar).hasClass('active')) {
			$(searchBar).removeClass('active');
		} else {
			$(searchBar).addClass('active');
			$(searchBarInput).focus();
		}

	}

});
