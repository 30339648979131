$(document).ready(function($) {

	let body = $('body');

	let offCanvasLeft = $('.off-canvas-left');
	let offCanvasRight = $('.off-canvas-right');

	let offCanvasLeftButton = $('.off-canvas-left-button');
	let offCanvasRightButton = $('.off-canvas-right-button');

	let offCanvasCloseButton = $('.off-canvas-close-button');

	let bookmarkView = $('#bookmark-view');
	let bookmarkViewTrigger = $('#bookmark-view-trigger');
	let bookmarkViewPosts = $('#bookmark-view-posts');
	let bookmarkViewLoadingSpinner = $('#bookmark-view-loading-spinner');
	let bookmarkViewBookAllButton = $('#bookmark-view-book-all-button');



	$(offCanvasLeftButton).click(openOffCanvasLeftWrapper);
	$(offCanvasRightButton).click(openOffCanvasRightWrapper);
	$(offCanvasCloseButton).click(closeOffCanvas);
	$(bookmarkViewTrigger).click(openBookmarkView);



	function closeOffCanvas() {
		closeOffCanvasLeft();
		closeOffCanvasRight();
		closeBookmarkView();
		hideCloseButton();
	}


	function openOffCanvasLeftWrapper() {
		closeOffCanvasRight();
		openOffCanvasLeft();
		showCloseButton();
	}


	function openOffCanvasLeft() {
		$(offCanvasLeft).addClass('active');
		$(offCanvasLeftButton).addClass('active');

		$(body).addClass('modal-open');

		$(offCanvasLeftButton).unbind('click');
		$(offCanvasLeftButton).click(closeOffCanvasLeftWrapper);

	}


	function closeOffCanvasLeftWrapper() {
		closeOffCanvasLeft();
		closeOffCanvasRight();
		hideCloseButton();
	}


	function closeOffCanvasLeft() {

		$(offCanvasLeft).removeClass('active');
		$(offCanvasLeftButton).removeClass('active');

		$(body).removeClass('modal-open');

		$(offCanvasLeftButton).unbind('click');
		$(offCanvasLeftButton).click(openOffCanvasLeftWrapper);

	}


	function openOffCanvasRightWrapper() {
		closeOffCanvasLeft();
		openOffCanvasRight();
		showCloseButton();
	}


	function openOffCanvasRight() {

		$(offCanvasRight).addClass('active');
		$(offCanvasRightButton).addClass('active');

		$(body).addClass('modal-open');

		$(offCanvasRightButton).unbind('click');
		$(offCanvasRightButton).click(closeOffCanvasRightWrapper);

	}


	function closeOffCanvasRightWrapper() {
		closeOffCanvasRight();
		closeOffCanvasLeft();
		hideCloseButton();
	}


	function closeOffCanvasRight() {

		$(offCanvasRight).removeClass('active');
		$(offCanvasRightButton).removeClass('active');

		$(body).removeClass('modal-open');

		$(offCanvasRightButton).unbind('click');
		$(offCanvasRightButton).click(openOffCanvasRightWrapper);

	}


	function showCloseButton() {

		$(offCanvasCloseButton).removeClass('d-none');
		setTimeout(function(){
			$(offCanvasCloseButton).addClass('active');
		}, 50);

	}


	function hideCloseButton() {

		$(offCanvasCloseButton).removeClass('active');
		setTimeout(function(){
			$(offCanvasCloseButton).addClass('d-none');
		}, 500);

	}



	// Scroll to
	$('.scrollToTop').click(scrollToTop);
	$('a[href^="#"].scrollTo').click(scrollTo);
	//$('.menu-item a[href^="#"]').click(scrollTo);

	$('.menu-item a').click(function() {
		closeOffCanvas();
	});



	function scrollToTop() {
		$('html, body').animate({ scrollTop: 0 }, 600);
		return false;
	}

	function scrollTo(event) {
		event.preventDefault();
		closeOffCanvas();
		$('html, body').animate({
			scrollTop: $($.attr(this, 'href')).offset().top
		}, 500);
	}

	function openBookmarkView() {

		if ($(bookmarkView).hasClass('active')) {
			closeOffCanvas();
		} else {
			$(bookmarkView).addClass('active');
			$(offCanvasRightButton).addClass('active');
			$(bookmarkViewLoadingSpinner).removeClass('inactive');
			$(bookmarkViewBookAllButton).addClass('inactive');

			$(body).addClass('modal-open');

			$(offCanvasRightButton).unbind('click');
			$(offCanvasRightButton).click(closeBookmarkView);

			// eslint-disable-next-line no-undef
			loadBookmarks();
		}

	}

	window.closeBookmarkViewExternal = function() {

		$(bookmarkView).removeClass('active');
		$(offCanvasRightButton).removeClass('active');

		$(body).removeClass('modal-open');

		$(offCanvasRightButton).unbind('click');
		$(offCanvasRightButton).click(openOffCanvasRightWrapper);

		setTimeout(() => {
			$(bookmarkViewPosts).empty();
			$(bookmarkViewLoadingSpinner).removeClass('inactive');
			$('#bookmark-view-buttons').addClass('inactive');
			$(bookmarkViewBookAllButton).addClass('inactive');
		}, 500);

	};

	function closeBookmarkView() {
		// eslint-disable-next-line no-undef
		closeBookmarkViewExternal();
	}

});
