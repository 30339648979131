import Litepicker from 'litepicker';
import 'litepicker/dist/plugins/mobilefriendly';
$(document).ready(function($) {

	let categoryDropdown = $('#seminar-category-dropdown');
	let educationalLeaveCheckbox = $('#seminar-filter-educational-leave');
	//let seminarSeriesCheckbox = $('#seminar-filter-seminar-series');
	let onlineSeminarCheckbox = $('#seminar-filter-online-seminar');
	let loadingScreen = $('#loading-screen');

	let picker;
	let pickerItem = document.getElementById('litepicker');

	$(categoryDropdown).change(changeCategoryDropdown);
	$(educationalLeaveCheckbox).click(clickEducationalLeave);
	//$(seminarSeriesCheckbox).click(clickSeminarSeries);
	$(onlineSeminarCheckbox).click(clickOnlineSeminar);

	function changeCategoryDropdown () {
		let categorySelected = $('option:selected', this); // eslint-disable-line
		let categoryId = categorySelected ? $(categorySelected).val() : null; // eslint-disable-line
		let startDate = picker.getStartDate();
		let endDate = picker.getEndDate();
		let hasEducationalLeave = document.getElementById('seminar-filter-educational-leave').checked;
		addParamsAndReload (categoryId, startDate, endDate, hasEducationalLeave);
	}

	if(pickerItem) {
		// eslint-disable-next-line no-unused-vars
		picker = new Litepicker({
			element: pickerItem,
			plugins: ['mobilefriendly'],
			format: 'DD.MM.YYYY',
			lang: 'de-DE',
			minDate: new Date(),
			singleMode: false,
			resetButton: true,
			numberOfColumns: 2,
			numberOfMonths: 2,
			// eslint-disable-next-line no-unused-vars
			setup: (picker) => {
				picker.on('selected', (startDate, endDate) => {
					let categorySelected = document.getElementById('seminar-category-dropdown');
					let category = categorySelected && categorySelected.value ? categorySelected.value : null;
					let hasEducationalLeave = document.getElementById('seminar-filter-educational-leave').checked;
					//let hasSemiarSeries = document.getElementById('seminar-filter-seminar-series').checked;
					let hasOnlineSeminar = document.getElementById('seminar-filter-online-seminar').checked;
					addParamsAndReload (category, startDate, endDate, hasEducationalLeave, false, hasOnlineSeminar);
				});
				picker.on('clear:selection', () => {
					let categorySelected = document.getElementById('seminar-category-dropdown');
					let category = categorySelected && categorySelected.value ? categorySelected.value : null;
					let hasEducationalLeave = document.getElementById('seminar-filter-educational-leave').checked;
					//let hasSemiarSeries = document.getElementById('seminar-filter-seminar-series').checked;
					let hasOnlineSeminar = document.getElementById('seminar-filter-online-seminar').checked;
					addParamsAndReload (category, null, null, hasEducationalLeave, false, hasOnlineSeminar);
				});
			},
		});
	}

	function clickEducationalLeave () {
		let categorySelected = document.getElementById('seminar-category-dropdown');
		let category = categorySelected && categorySelected.value ? categorySelected.value : null;
		let startDate = picker.getStartDate();
		let endDate = picker.getEndDate();
		let hasEducationalLeave = this.checked;
		//let hasSemiarSeries = document.getElementById('seminar-filter-seminar-series').checked;
		let hasOnlineSeminar = document.getElementById('seminar-filter-online-seminar').checked;

		addParamsAndReload (category, startDate, endDate, hasEducationalLeave, false, hasOnlineSeminar);
	}

	/*
	function clickSeminarSeries () {
		let categorySelected = document.getElementById('seminar-category-dropdown');
		let category = categorySelected && categorySelected.value ? categorySelected.value : null;
		let startDate = picker.getStartDate();
		let endDate = picker.getEndDate();
		let hasEducationalLeave = document.getElementById('seminar-filter-educational-leave').checked;
		let hasSemiarSeries = this.checked;
		let hasOnlineSeminar = document.getElementById('seminar-filter-online-seminar').checked;

		addParamsAndReload (category, startDate, endDate, hasEducationalLeave, hasSemiarSeries, hasOnlineSeminar);
	}
	*/

	function clickOnlineSeminar () {
		let categorySelected = document.getElementById('seminar-category-dropdown');
		let category = categorySelected && categorySelected.value ? categorySelected.value : null;
		let startDate = picker.getStartDate();
		let endDate = picker.getEndDate();
		let hasEducationalLeave = document.getElementById('seminar-filter-educational-leave').checked;
		//let hasSemiarSeries = document.getElementById('seminar-filter-seminar-series').checked;
		let hasOnlineSeminar = this.checked;

		addParamsAndReload (category, startDate, endDate, hasEducationalLeave, false, hasOnlineSeminar);
	}

	function addParamsAndReload (categoryId, startDate, endDate, educationalLeave, semiarSeries, onlineSeminar) {
		$(loadingScreen).addClass('active');
		let isFirst = true;
		let path = window.location.origin + window.location.pathname;

		if(categoryId && !categoryId.includes('http')) {
			path += (isFirst ? '?' : '&');
			path += 'category=' + categoryId;
			isFirst = false;
		}

		if(startDate) {
			path += (isFirst ? '?' : '&');
			path += 'start-date=' + startDate.format('YYYY-MM-DD');
			isFirst = false;
		}

		if(endDate) {
			path += (isFirst ? '?' : '&');
			path += 'end-date=' + endDate.format('YYYY-MM-DD');
			isFirst = false;
		}

		if(educationalLeave) {
			path += (isFirst ? '?' : '&');
			path += 'educational-leave=true';
			isFirst = false; // eslint-disable-line
		}

		if(semiarSeries) {
			path += (isFirst ? '?' : '&');
			path += 'seminar-series=true';
			isFirst = false; // eslint-disable-line
		}

		if(onlineSeminar) {
			path += (isFirst ? '?' : '&');
			path += 'online-seminar=true';
			isFirst = false; // eslint-disable-line
		}

		window.location.href = path + '#filter';
	}
});
