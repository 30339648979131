import $ from 'jquery';
window.jQuery = $;
window.$ = $;

import friendlyChallenge from 'friendly-challenge/widget';
window.friendlyChallenge = friendlyChallenge;
import 'bootstrap';

import './offcanvas';
import './smoothScroll';
// import './modal';

// import './aos';

// import './scrollspy';

import './tooltips';
// import './bookmark';
import './seminar-filter';
import './searchbar';
import './watchForHover';

import './browserSync';
